@import "./src/assets/sass/prepends.scss";

article {
  text-align: center;

  p {
    text-shadow: 0 0 0.25rem #000;
    > * {
      display: inline-block;
    }
  }

  .logo-wraper {
    display: block;
    max-width: 400px;
    margin: 1rem auto;
  }
  #login-button {
    margin: 2.5rem;
  }
}
