@import "./src/assets/sass/prepends.scss";

#nav-sw {
  position: relative;
  display: inline-block;
  height: 30px;
  width: 35px;
  background: transparent;
  border: 1px solid $text-white;
  border-radius: 8px;

  span,
  span::before,
  span::after {
    position: absolute;
    content: "";
    height: 1px;
    width: 20px;
    background: $text-white;
    display: block;
    transition: 0.5s;
  }
  span.menuClosed {
    top: 14px;
    left: 7px;
  }
  span.menuClosed::before {
    top: -6px;
    left: 0;
  }
  span.menuClosed::after {
    top: 6px;
    left: 0;
  }
  span.menuOpened {
    transform: rotate(-45deg);
  }
  span.menuOpened::before,
  span.menuOpened::after {
    top: 0px;
    left: 0px;
    transform: rotate(90deg);
  }
}
