@import "./src/assets/sass/prepends.scss";

article {
  color: $text-white;
  text-align: center;
  position: relative;

  h2 {
    margin: 1rem;
    font-size: 1.25rem;
  }
}
