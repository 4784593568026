@import "./src/assets/sass/prepends.scss";

header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  color: $text-white;
  background-color: $bg-dark;

  & > * {
    flex: auto 0 0;
    align-self: center;
    margin: 0;
  }
  & h1 {
    height: 30px;
    font-size: 30px;
    line-height: 1;
  }
}
