@import "./src/assets/sass/prepends.scss";

footer {
  display: block;
  background-color: $bg-dark;
  padding: 0.5rem;
  color: $text-white;
  font-size: 0.75rem;
  line-height: 1.25;
  text-align: center;
  
  & > * {
    display: inline-block;
    &:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
  a:hover {
    text-decoration: underline;
  }
}
