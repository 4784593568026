@import "./src/assets/sass/prepends.scss";

header,
footer {
  background-color: transparent !important;
}
div#app {
  background-image: linear-gradient(
      rgba(56, 100, 113, 0.8),
      rgba(56, 100, 113, 0.8)
    ),
    url("@/assets/img/LP-BG.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}
div#bg-wraper {
  color: $text-white;
  transition: background-color 0.5s;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &.menuOpen {
    background-color: $bg-dark;
  }
  &.menuClose {
    background-color: transparent;
  }
}
main > article {
  border-top: 1px solid $text-white;
}
article {
  &.transition-router-enter-active,
  &.transition-router-leave-active {
    transition: opacity 0.1s;
  }
  &.transition-router-enter-from,
  &.transition-router-leave-to {
    opacity: 0;
  }
}
