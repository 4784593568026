div#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header, footer, main{
  box-sizing: border-box;
}

header {
  height: 3rem
}

main {
  flex: 1;
}

main > article {
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 0.5rem;
  padding-bottom: 5rem;

  h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    padding: 0;
    margin: 0 0 1rem 0;
    color: $text-black;
  }
}