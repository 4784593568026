$bg-dark: #386471;
// $bg-dark: #386370;
$bg-dark-hover: #498293;
// $bg-light: #bdd4da;
$bg-light: #7aa5b2;
$bg-light-lighten3: #9abbc5;
$bg-white: #f3f8f9;
$bg-red-light: #e8c3c3;
$bg-red-dark: rgb(127, 54, 73);
$bg-blue-dark: rgb(64, 97, 151);
$bg-info-dark: rgb(42, 151, 154);
$bg-green-dark: rgb(57, 113, 104);
$bg-secondary-dark: #666;
$bg-secondary-light: #aaa;
$bg-warining-dark: rgb(182, 135, 48);

$text-white: #eee;
$text-dark: #aaa;
$text-black: #333;
$text-link-blue: #0058B3;
$text-blue: #0058B3;
$text-red: #b94a48;
$text-green: #3ccf5f;
