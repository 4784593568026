@import "./src/assets/sass/prepends.scss";

article {
  color: $text-white;
  text-align: center;
  position: relative;

  h2 {
    margin: 1rem;
    font-size: 1.25rem;
  }
  .login-wraper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  #login-help {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 1rem;
    text-align: center;

    a {
      text-decoration: underline;
    }
  }
}
